import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from "react-helmet"

const NotFound = () => (
  <Layout>
    <Helmet>
          <meta charSet="utf-8" />
          <title>C4Scale - 404 page</title>
          <meta name="description" content="Page not found" />
        </Helmet>
    <h1>Not Found</h1>
    <p>You just hit a page that doesn&#39;t exist...</p>
  </Layout>
);

export default NotFound;
